import * as type from 'constants/action_types/card_info'
import Api from 'services/api/card_info'

export const fetchCardInfo = () => async dispatch => {
  dispatch({
    type: type.PENDING_CARD_INFO_ACTION,
    payload: 'cardInfoFetch'
  })

  const res = await Api.fetchCardInfo()
  if (res.ok) {
    dispatch({
      type: type.SET_CARD_INFO,
      payload: res.data
    })
  }
}

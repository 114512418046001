import * as type from 'constants/analytics'
import { trackServerSideIdentify } from 'services/api/analytics'
import { logExceptionNoConsole } from '../config/sentry'

export const identifyRequestsAreInProgress = () => (dispatch, getState) => {
  const { inProgress } = getState().analytics
  return inProgress
}

export const beginIdentifyRequests = () => dispatch => {
  dispatch({
    type: type.IDENTIFY_REQUESTS_IN_PROGRESS,
    payload: true
  })
}

export const finishIdentifyRequests = () => dispatch => {
  dispatch({
    type: type.IDENTIFY_REQUESTS_IN_PROGRESS,
    payload: false
  })
}

export const addIdentifyRequest = requestParams => dispatch => {
  dispatch({
    type: type.ADD_IDENTIFY_REQUEST,
    payload: requestParams
  })
}

export const removeIdentifyRequest = () => dispatch => {
  dispatch({
    type: type.REMOVE_IDENTIFY_REQUEST
  })
}

export const executeIdentifyRequests = () => async (dispatch, getState) => {
  const { identifyRequests } = getState().analytics
  const firstRequest = identifyRequests[0]
  if (!firstRequest) {
    dispatch(finishIdentifyRequests())
    return
  }
  try {
    dispatch(beginIdentifyRequests())
    await trackServerSideIdentify(firstRequest)
    dispatch(removeIdentifyRequest())
    dispatch(executeIdentifyRequests())
  } catch (error) {
    logExceptionNoConsole(error)
  }
}

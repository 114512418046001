import * as type from 'constants/action_types/checklist'

import { checklistIncentiveEligible } from 'helpers/checklist'

import { sendSegmentTrackEvent, safeSegmentCall } from 'services/segment'
import Api from 'services/api/checklist'

export const loadChecklistData = () => async (dispatch, getState) => {
  const { user } = getState().globalData

  if (!['find_product', 'more_products'].includes(user?.objective)) return

  dispatch({
    type: type.PENDING_CHECKLIST_ACTION,
    payload: 'loadChecklistData'
  })

  const res = await Api.fetchChecklist()

  if (res.ok) {
    dispatch({
      type: type.SET_CHECKLIST,
      payload: res.data
    })
  }

  dispatch({
    type: type.RESET_CHECKLIST,
    payload: 'loadChecklistData'
  })
}

export const checkChecklistItem = position => async (dispatch, getState) => {
  const {
    checklist: state,
    globalData: { membershipInfo, user }
  } = getState()

  if (!state.checklists.length) return

  const allChecklistItems = state.checklists
    .map(({ checklist_items }) => checklist_items)
    .flat()

  const checklistItem = allChecklistItems.find(i => i.position === position)

  if (!checklistItem) {
    throw new Error(`Couldn't find an item with the position: ${position}`)
  }

  if (checklistItem.completed) return

  const checklistComplete = allChecklistItems
    .filter(i => i.position !== position)
    .every(i => i.completed)

  safeSegmentCall(() => {
    sendSegmentTrackEvent('Checklist Item Checked', {
      type: 'find_product',
      item: checklistItem.name,
      checklistComplete
    })
  })

  const showIncentive = checklistIncentiveEligible(user, membershipInfo)

  if (showIncentive && checklistComplete) {
    Api.applyCoupon()
  }

  dispatch({
    type: type.SET_CHECKLIST_ITEM_CHECKED,
    payload: { itemId: checklistItem.id, completed: true }
  })

  const res = await Api.checkItem(checklistItem.id)

  if (!res.ok) {
    dispatch({
      type: type.SET_CHECKLIST_ITEM_CHECKED,
      payload: { itemId: checklistItem.id, completed: false }
    })
  }
}

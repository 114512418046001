import * as type from 'constants/banners'

/**
 * @typedef {Object} BannerData
 * @property {string} id - required
 * @property {string|React.ReactNode} message
 * @property {string} [type]
 * @property {boolean} [dismissible]
 * @property {number} [showAfter]
 *
 */

/**
 *
 * @param {BannerData} bannerData
 * @returns
 */
export const createBanner = bannerData => dispatch => {
  dispatch({
    type: type.ADD_BANNER,
    payload: {
      ...bannerData
    }
  })
}

export const removeBanner = id => dispatch => {
  dispatch({
    type: type.REMOVE_BANNER,
    payload: id
  })
}

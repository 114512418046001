import { SET_CHATBOT_STATE } from 'constants/action_types'

export const setChatbotState = payload => dispatch =>
  dispatch({ type: SET_CHATBOT_STATE, payload })

// Opens chatbot and create a report
export const openChatbotReport = params => dispatch =>
  dispatch(setChatbotState({ isOpen: true, createReportParams: params }))

export const toggleChatbot = () => (dispatch, getState) => {
  const { isOpen } = getState().chatbot
  dispatch(setChatbotState({ isOpen: !isOpen }))
}
